.gameInfo {
  margin-top: 25px;
}

.league-game {
  display: flex; /* Use flexbox for layout */
  justify-content: space-around; /* Evenly space items */
  align-items: center; /* Vertically align items */
  background-color: var(--main-bg-color-dark);
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.league-game .user {
  font-size: 12px;
  color: var(--text-color-grey-light);
  text-align: left;
}

.league-game .team-name {
  font-size: 18px;
}
.league-game .score {
  font-size: 18px;
}

.league-game .score .winner-box {
  font-size: 18px;
}

.league-game .score .loser-box {
  font-size: 18px;
}

.league-game .left-align {
  text-align: left;
  align-items: flex-start;
}
.league-game .right-align {
  text-align: right;
  align-items: flex-end;
}

.league-game .ownersTeam .team-name {
  color: var(--text-highlight-color)
}

.league-game .vs {
  margin: 0 10px; /* Add spacing around "vs" */
  text-align: center;
  
}

.score-container {
  display: flex;
  justify-content: space-between;
}

.score-position {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.score-position-title {
  font-weight: bold;
}

.starter-team-name {
  font-size: 24px;
  text-align: left;
  font-weight: bold;
}

.starter-main-score {
  font-size: 13px;
  align-self: flex-end;
  font-weight: bold;
  color: var(--text-color-grey);
}

.starters-head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  
}

// For starters and bench
.head-row {
  background-color: var(--main-bg-color-dark);
}

.bench-table {
  margin-top: 45px;
  
}

.head-text {
  display: flex;
  justify-content: left;
  align-items: left;
  font-weight: bold;
}

.starters .head-text {
  font-size: 24px;
}

.bench .head-text {
  font-size: 20px;
}

.starters .gameState {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 18px;
}

.starters .gameState .Final {
  color: var(--complete-color);
  margin-left: 10px;
}

.winning-team {
  color: var(--winner-color);
}

.winner-box {
  color: var(--winner-color);
  font-size: 13px;
}

.losing-team {
  color: var(--loser-color);
}

.loser-box {
  color: var(--loser-color);
  font-size: 13px;
}

.score-header-canvas {
  display: block; /* or inline-block, depending on your layout */
  /* Add any other CSS properties you need to maintain the layout */
}

.starter-player {
  font-size: 16px;
  text-align: left;
  font-weight: normal;
  display: inline-block;
}

.starter-player-score {
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
  color: var(--text-color-grey);
  font-weight: normal;
  display: inline-block;
}

.starter-player-opponent {
  font-size: 14px;
  text-align: left;
  color: var(--text-color-grey);
  font-weight: normal;
  display: inline-block;
}

.matchup-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0.8em; /* Makes the text smaller */
  padding: 0 1em; /* Adds some padding on the sides */
}

.matchup-link {
  font-weight: bold !important;
  font-size: large !important;
}

.player-info-table {
  table-layout: fixed;
  width: 100%; /* Ensures the table takes up the full width of its container */
}

.player-info-table .team-row {
  width: 45%; /* Adjust as needed */
}

.player-info-table .position-row {
  width: 10%; /* Adjust as needed */
}