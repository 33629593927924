/* Component Specific Style */

.league-quick-view {
  background-color: var(--secondary-bg-color);
}

.league-quick-view:hover {
  color: var(--highlight-text-color) !important;
}

.league-name {
  color: var(--text-color);
}

.league-name:hover {
  color: var(--highlight-text-color);
}

.league-name-commish {
  color: var(--secondary-text-color);
}

.league-name-user {
  color: var(--accent-text-color);
}

.home {
  color: #fff;
}