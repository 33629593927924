.good_bid {
  animation: good_bid_blinker 1s linear 2;
}
@keyframes good_bid_blinker {
  50% {
    background-color: green;
  }
}

.bad_bid {
  animation: bad_bid_blinker 0.5s linear 3;
}

@keyframes bad_bid_blinker {
  50% {
    opacity: 0;
    background-color: red;
  }
}