body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-bg-color: #282c34;
  --secondary-bg-color: #20232a;
  --third-bg-color: #282c34;
  --main-bg-color-light: #f5f5f5; /* Light version of main background color */
  --main-bg-color-dark: #212121; /* Dark version of main background color */
  --primary-color: #1976d2;
  --secondary-color: #ff4081;
  --accent-color: #4caf50;
  --text-color: #ffffff;
  --secondary-text-color: #9e9e9e; /* Slightly off color that matches the color scheme */
  --accent-text-color: #8bc34a; /* Dull green color for accent text */
  --highlight-text-color: #b0e0e6; /* Light blue color for highlighted text */
  --text-color-grey: #9e9e9e;
  --text-color-grey-light: #d3d3d3;
  --text-highlight-color: #b0e0e6;
  
  
  /* Light Colors */
  --primary-light-color: #64b5f6;
  --secondary-light-color: #ff80ab;
  --accent-light-color: #81c784;
  --text-light-color: #f5f5f5;
  
  /* Dark Colors */
  --primary-dark-color: #0d47a1;
  --secondary-dark-color: #c51162;
  --accent-dark-color: #388e3c;
  --text-dark-color: #212121;
  
  /* Winners and Losers Colors */
  --winner-color: #00ff00; /* Green color for winners */
  --loser-color: #ff0000; /* Red color for losers */

  
  --not-started-color: #ff9800; /* Orange color for not started */
  --in-progress-color: #2196f3; /* Blue color for in progress */
  --complete-color: #4caf50;
}